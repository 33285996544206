import React from 'react';
import './App.css';

class App extends React.Component {
  state = {
    images: 5,
    currentImage: 1
  }

  handleChangeCurrentImage = (action) => {
    if (action === "next") {
      if (this.state.currentImage < this.state.images) {
        this.setState((prev) => ({
          currentImage: prev.currentImage + 1
        }));
      }
    } else {
      if (this.state.currentImage > 1) {
        this.setState((prev) => ({
          currentImage: prev.currentImage - 1
        }));
      }
    }

  }

  componentDidMount = () => {
    console.log("aaa".length);
  }

  render() {
    return (
      <>
        <Slider currentImage={this.state.currentImage} images={this.state.images} click={this.handleChangeCurrentImage} />

        <Status currentImage={this.state.currentImage} images={this.state.images} />
      </>
    )
  }
}

const Slider = (props) => {
  return (
    <>
      <div className="container">
        <Button action="prev" click={props.click} currentImage={props.currentImage} images={props.images} />
        <Button action="next" click={props.click} currentImage={props.currentImage} images={props.images} />
        {/* <img src={require(`../img/${props.currentImage}.jpg`)} alt={`Zdjęcie numer ${props.currentImage}`} /> */}
        <div className="image" style={{ backgroundImage: `url(${require(`../img/${props.currentImage}.jpg`)})` }} ></div>
      </div>
    </>
  );
}

const Button = props => {
  let button = "";
  if (props.action === "prev") {
    button = "<";
    return (
      <div className={`button ${props.currentImage === 1 ? "disable" : ""}`} onClick={() => props.click(props.action)} >
        {button}
      </div >
    );
  } else {
    button = ">";
    return (
      <div className={`button ${props.currentImage === 5 ? "disable" : ""}`} onClick={() => props.click(props.action)} >
        {button}
      </div >
    );
  }
}

const Status = props => {
  return (
    <p className="status">{props.currentImage} / {props.images}</p>
  )
}

export default App;
